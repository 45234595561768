import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Config } from '../models/common';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    private config: Config = {
        appName: 'ivm admin',
        apiUrl: 'api/v1',
        avatarExtendInitials: false,
        appOverviewUrl: null,
        logoutUrl: null,
        hasDarkLogo: true,
        appCode: null,
        homeRoute: null,
        defaultAppCode: 'newsletters',
        isIE: false
    };
    private darkMode$ = new BehaviorSubject<boolean>(null);

    constructor(private TitleService: Title, private Router: Router, public Route: ActivatedRoute) {
        const darkMode = parseInt(localStorage.getItem('darkMode'));
        if (
            (darkMode === undefined || darkMode === null) &&
            window.matchMedia('(prefers-color-scheme: dark)').matches
        ) {
            this.setDarkMode();
        } else if (darkMode) {
            this.setDarkMode();
        } else {
            this.unsetDarkMode();
        }
        var ua = window.navigator.userAgent;
        if (ua.indexOf('MSIE ') != -1 || ua.indexOf('Trident/') != -1) {
            this.config.isIE = true;
        }
    }

    public initApp(code: any) {
        let title = '';
        let homeRoute = '';
        let appPrimaryColor = '';
        let appPrimaryColorLight = '';
        let appPrimaryColorDark = '';
        switch (code) {
            case 'discountcodes':
                title = 'Kortingcodes';
                homeRoute = 'kortingscodes';
                appPrimaryColor = '#4FB0D4';
                appPrimaryColorLight = '#eaeff3';
                appPrimaryColorDark = '#479ebe';
                break;
            case 'newsletters':
                title = 'Nieuwsbrieven';
                homeRoute = 'dashboard';
                appPrimaryColor = '#4FB0D4';
                appPrimaryColorLight = '#eaeff3';
                appPrimaryColorDark = '#479ebe';
                break;
            case 'rijveilig':
                title = 'Rijveilig';
                homeRoute = 'werkzame-stoffen';
                appPrimaryColor = '#479EBE';
                appPrimaryColorLight = '#EAF0F3';
                appPrimaryColorDark = '#407D8B';
                break;
            default:
                window.location.href = this.config.appOverviewUrl;
                break;
        }

        this.setAppPrimaryColor(appPrimaryColor);
        this.setAppPrimaryColorLight(appPrimaryColorLight);
        this.setAppPrimaryColorDark(appPrimaryColorDark);
        this.TitleService.setTitle(title);
        this.config.appCode = code;
        this.config.homeRoute = homeRoute;
        if (window.location.pathname === '/' || window.location.pathname === '') {
            this.Router.navigateByUrl(`/${homeRoute}`);
        }
    }

    public setAppPrimaryColor(color: string) {
        //Set color on root
        const root = document.documentElement;
        root.style.setProperty('--primary-color', color);
    }

    public setAppPrimaryColorLight(color: string) {
        const root = document.documentElement;
        root.style.setProperty('--primary-color-light', color);
    }

    public setAppPrimaryColorDark(color: string) {
        const root = document.documentElement;
        root.style.setProperty('--primary-color-dark', color);
    }

    public getConfig(): Config {
        return this.config;
    }

    public setDarkMode() {
        this.darkMode$.next(true);
        localStorage.setItem('darkMode', '1');
    }

    public unsetDarkMode() {
        this.darkMode$.next(false);
        localStorage.setItem('darkMode', '0');
    }

    public toggleDarkMode() {
        const newValue = !this.darkMode$.getValue();
        newValue ? this.setDarkMode() : this.unsetDarkMode();
    }

    public getDarkMode() {
        return this.darkMode$;
    }
}
