<div class="search-input {{classes}}" [class.search-input--clear]="model" [class.search-input--loading]="loading">
    <div class="input-wrap">
        <input
            #myinput
            type="text"
            [(ngModel)]="model" 
            (ngModelChange)="modelChange.emit(model)"
            (keyup.enter)="enter.emit()"
            [placeholder]="placeholder"
            [autocomplete]="false"
            (blur)="hasBlur.emit()"
            (focus)="hasFocus.emit()"
            [class.error-field]="error"
            [disabled]="disabled"
            [ngStyle]="{'max-width': maxWidth ? maxWidth : ''}"
        />
        <button *ngIf="classes.indexOf('search-input--button') !== -1" (click)="enter.emit()"><i class="im-icon im-icon-search"></i></button>
    </div>

    <i class="im-icon im-icon-x-round-filled x" (click)="clear.emit()" *ngIf="!loading && !disabled"></i>
    <app-inline-spinner *ngIf="loading"></app-inline-spinner>
</div>
<span *ngIf="error" class="error-field-display">{{error}}</span>