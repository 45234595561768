import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCropperComponent } from '../../modals/image-cropper/image-cropper.component';

@Component({
    selector: 'app-field-image',
    templateUrl: './field-image.component.html',
    styleUrls: ['./field-image.component.scss']
})
export class FieldImageComponent implements OnInit {
    @Input() label?: string;
    @Input() optionalString?: string;
    @Input() hideLabel?: boolean;
    @Input() error?: string;
    @Input() url?: string;
    @Input() file: File = null;
    @Output() setActiveModal = new EventEmitter();
    @Output() sendFile = new EventEmitter();
    imgPreview: string = null;

    localFile: File = null;
    file2: File = null;

    constructor(private ModalService: NgbModal) {}

    ngOnInit(): void {}

    hasFile() {
        return this.file;
    }

    fileChange() {
        setTimeout(() => {
            this.processFile(this.file2);
        }, 1);
    }

    processFile(imageInput: any) {
        setTimeout(() => {
            if (
                imageInput.type === 'image/png' ||
                imageInput.type === 'image/jpeg' ||
                imageInput.type === 'image/jpg'
            ) {
                this.localFile = imageInput;
            } else {
                this.localFile = imageInput.files[0];
            }
            this.setActiveModal.emit(true);
            const modalRef = this.ModalService.open(ImageCropperComponent, {
                windowClass: 'add-modal'
            });
            modalRef.componentInstance.file = this.localFile;
            modalRef.componentInstance.added.subscribe((file: File) => {
                this.file = file;
                this.url = '';
                this.setActiveModal.emit(false);
                this.imgPreview = URL.createObjectURL(file);
                this.sendFile.emit(file);
            });
            this.file2 = null;
        }, 1);
    }

    removeImage() {
        this.file = null;
        this.url = '';
        this.imgPreview = null;
        this.sendFile.emit(null);
    }
}
