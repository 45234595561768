import { Pipe, PipeTransform } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import moment from 'moment';
import 'moment/locale/nl-be';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';
moment.locale('nl-be');

const keywordAt = 'om';
const keywordToday = 'vandaag';
const today = moment();

@Pipe({ name: 'formatTs' })
export class formatTsPipe implements PipeTransform {
    transform(ts: string, format: string): string {
        if (!ts || !format || !moment(ts).isValid()) {
            return ts || '-';
        }
        switch (format) {
            case 'prettyTs':
                return moment(ts).calendar(null, {
                    sameElse: 'DD-MM-YYYY'
                });
                break;

            default:
                break;
        }
        return moment(ts).format(format);
    }
}
@Pipe({ name: 'display' })
export class displayPipe implements PipeTransform {
    transform(value: any, type): any {
        if (value === 0) {
            return 0;
        }
        if (!value) {
            return '-';
        } else return value;
    }
}
@Pipe({ name: 'customCurrency' })
export class customCurrencyPipe implements PipeTransform {
    constructor(private cp: CurrencyPipe) {}
    transform(value: any): any {
        if (value === 0 || value === '0') {
            return '€ 0,00';
        }
        if (!value) {
            return '-';
        } else {
            // // float
            // if (value % 1 !== 0) {
            //     console.log('float');
            // }
            // // number
            // if (value % 1 === 0) {
            //     console.log('number');
            // }
            let returnValue = this.cp.transform(parseFloat(value).toString(), '€ ').replace(/,/g, '.');
            const n = returnValue.lastIndexOf('.');
            if (n >= 0 && returnValue.length) {
                returnValue = returnValue.substring(0, n) + ',' + returnValue.substring(n + 1);
            }
            return returnValue;
        }
    }
}
@Pipe({ name: 'floatDisplay' })
export class floatDisplayPipe implements PipeTransform {
    transform(value: any, type?, fixed?: boolean): any {
        if (value === 0) {
            return 0;
        }
        if (!value) {
            return '-';
        }

        if (!isNaN(parseFloat(value))) {
            let r = parseFloat(value).toString().replace(/\./g, ',');
            if (fixed) {
                r = value.toFixed(2).toString().replace(/\./g, ',');
            }
            if (type && type == 'thousandSeparator') {
                return r.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            } else {
                return r;
            }
        }

        return value;
    }
}
@Pipe({ name: 'customPercentage' })
export class percentagePipe implements PipeTransform {
    transform(value: any): any {
        if (value === 0 || value === '0') {
            return '0%';
        }
        if (!value) {
            return '-';
        } else return value.toString().replace('.', ',') + '%';
    }
}
@Pipe({
    name: 'highlight'
})
export class hightlightPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    transform(value: any, args: any): any {
        if (!args) {
            return value;
        }
        // Match in a case insensitive maneer
        const re = new RegExp(args, 'gi');
        const match = value.match(re);

        // If there's no match, just return the original value.
        if (!match) {
            return value;
        }

        const replacedValue = value.replace(re, '<mark>' + match[0] + '</mark>');
        return this.sanitizer.bypassSecurityTrustHtml(replacedValue);
    }
}

@Pipe({
    name: 'callback',
    pure: false
})
export class callbackPipe implements PipeTransform {
    transform(items: any[], callback: (item: any) => boolean): any {
        if (!items || !callback) {
            return items;
        }
        return items.filter((item) => callback(item));
    }
}

@Pipe({
    name: 'safe'
})
export class SafePipe implements PipeTransform {
    constructor(protected sanitizer: DomSanitizer) {}

    public transform(value: any, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
        switch (type) {
            case 'html':
                return this.sanitizer.bypassSecurityTrustHtml(value);
            case 'style':
                return this.sanitizer.bypassSecurityTrustStyle(value);
            case 'script':
                return this.sanitizer.bypassSecurityTrustScript(value);
            case 'url':
                return this.sanitizer.bypassSecurityTrustUrl(value);
            case 'resourceUrl':
                return this.sanitizer.bypassSecurityTrustResourceUrl(value);
            default:
                throw new Error(`Invalid safe type specified: ${type}`);
        }
    }
}
