<div class="field field-richtext" [class.field-richtext--expanded]="expanded">
    <span *ngIf="label" class="label">{{label}}</span>
    <quill-editor 
        (onEditorCreated)="editorCreated($event)"
        customToolbarPosition="top"
        placeholder="Schrijf hier je tekst..."
        [modules]="modules"
        (onFocus)="onFocus($event)"
        (onBlur)="onBlur($event)"
        (click)="clicked.emit()"
        [class.error-field]="error"
        [(ngModel)]="model"
        (onContentChanged)="onChange($event)">
        <div quill-editor-toolbar>
            <span class="ql-formats">
                <!-- <button class="ql-header" value="1"></button>
                <button class="ql-header" value="2"></button>
                <button class="ql-header" value="3"></button> -->
                <select class="ql-header">
                    <option selected></option>
                    <!-- <option value="1"></option> -->
                    <option value="2"></option>
                    <option value="3"></option>
                  </select>
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
                <select class="ql-align" [title]="'Aligment'">
                    <option selected></option>
                    <option value="center"></option>
                    <option value="right"></option>
                    <option value="justify"></option>
                </select>
                <!-- <button (click)="openLinkModal()" id="link-button"><i class="im-icon im-icon-link"></i></button> -->
                <button class="ql-link"></button>
                <button class="ql-list" value="ordered"></button>
                <button class="ql-list" value="bullet"></button>
                <button class="ql-indent" value="-1"></button>
                <button class="ql-indent" value="+1"></button>
            </span>
        </div>
    </quill-editor>
    <span *ngIf="error" class="error-field-display">{{error}}</span>
</div>