import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { HelpersService } from '../../../services/helpers.service';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, OnChanges {
    @Input() heads: { name: string; code: string; sortable?: boolean }[];
    @Input() data: any;
    @Input() sort?: { code: string; dir: 'asc' | 'desc' };
    @Input() emptyState?: string;
    @Input() RPP?: number = 20;
    @Input() startRow?: number = 0;
    @Input() totalRows?: number = null;
    @Input() ready?: boolean = true;
    @Input() loading: boolean = true;
    @Input() exportLabel: string = '';
    @Input() exportBusy: boolean = false;
    @Input() canAddRows: boolean = false;
    @Input() addRowFormSTR: any = {};
    @Input() classes: string = '';
    @Input() changeSequence: boolean = false;
    @Output() sortChange = new EventEmitter();
    @Output() clickData = new EventEmitter();
    @Output() clickAction = new EventEmitter();
    @Output() startRowChange = new EventEmitter();
    @Output() clickExport = new EventEmitter();
    @Output() addRowToTable = new EventEmitter();

    clickable = false;
    helper: any = {};
    shimmerRows = [];
    popoverHelper: any = null;

    constructor(public HelpersService: HelpersService) {}

    ngOnInit(): void {
        this.clickable = this.clickData.observers.length > 0;
        this.shimmerRows = Array(20 || this.RPP);
    }

    clickOutsideActions() {
        // setTimeout(() => {
        //     this.actionIndex = null;
        //     console.log('u');
        // }, 1000);
    }
    getActionIndex() {
        // console.log('get actionindex ' + this.helper.actionIndex);
        return this.helper.actionIndex;
    }
    setActionIndex(i) {
        // console.log('--- set actionindex ' + i);
        this.helper.actionIndex = i;
    }

    ngOnChanges(changes: SimpleChanges) {
        // if ((changes.data || changes.sort) && this.sort && this.sort.code && this.sort.dir && this.data) {
        //     this.localSort();
        // }
    }

    // localSort() {
    //     const _this = this;
    //     this.data = this.data.sort((a: any, b: any) => {
    //         let valA = a[_this.sort.code];
    //         let valB = b[_this.sort.code];
    //         switch (a[_this.sort.code].type) {
    //             case 'avatar':
    //                 valA = a[_this.sort.code].title;
    //                 valB = b[_this.sort.code].title;
    //                 break;
    //             case 'ts':
    //                 valA = a[_this.sort.code].ts;
    //                 valB = b[_this.sort.code].ts;
    //                 break;
    //             default:
    //                 break;
    //         }
    //         if (valA < valB) {
    //             return _this.sort.dir === 'asc' ? -1 : 1;
    //         }
    //         if (valA > valB) {
    //             return _this.sort.dir === 'asc' ? 1 : -1;
    //         }
    //         return 0;
    //     });
    // }

    identify(index, item) {
        return item.id || index;
    }

    dismissPopover() {
        setTimeout(() => {
            this.popoverHelper = null;
        }, 1);
    }

    getSortingClass(head: any) {
        if (head.sortable && this.sort.code === head.code) {
            return this.sort.dir;
        }
    }

    clickSort(head: any) {
        let result = this.sort;
        if (this.sort.code === head.code) {
            result.dir = this.sort.dir === 'asc' ? 'desc' : 'asc';
        } else {
            result = { code: head.code, dir: 'asc' };
        }
        this.sortChange.emit(result);
    }

    // resetFormSTR() {
    //     this.formSTR = {};
    // }

    addRow(formSTR: any) {
        this.addRowToTable.emit(formSTR);
        // this.resetFormSTR();
    }
}
